import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import HomePage from "../container/home"
import TermsPage from "../container/terms"
import serviceData from "../Data/service.json"

const homeData = {
  header:
    "Software solutions for all needs. widen your business opportunities with our services.",
  sub: "As a solution providing company we offer a wide range of consulting, development and quality testing services. for more than 10 years in the field of web and mobile development, our team had created hundreds of websites, mobile applications, different kinds of software and individual components, and more.",
}

const termpage = {
  header: "Services",
  desc: "The outcomes you need are at the centre of everything we do. through our success experiences, success extensions, and success services, we can help ensure your business vision and tech strategy are working as one to create the right results from the start – and every day after.",
  data: serviceData,
}

const ServicePage = () => (
  <Layout>
    <Seo title="Service" />
    <HomePage data={homeData} />
    <TermsPage data={termpage} />
  </Layout>
)

export default ServicePage
